<template>
  <span
    :class="`fi-${nameLowerCase}`"
    class="fi"
    v-if="icon"
    v-html="icon"
  />
</template>

<style scoped>
.fi {
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
</style>

<script setup lang="ts">
const props = defineProps<{
  name?: string
}>()

const nameLowerCase = computed(() => props.name?.toLowerCase())
const icon = ref<string | undefined>(undefined)

watch(nameLowerCase, () => {
  if (nameLowerCase.value) {
    loadIcon()
  }
})

/**
 * Auto-load icons with https://vitejs.dev/guide/features.html#glob-import
 *
 * To dynamically import SVG files from the 'flag-icons' package and
 * organize them into an object where the keys are filenames and the
 * values are the raw SVG contents of the corresponding icons.
 *
 * As dynamic imports with absolute paths are not supported,
 * Vite create dynamic imports for each module with relative paths.
 * And at runtime, the browser will try to load the flag icons
 *
 */
const icons = Object.fromEntries(
  Object.entries(import.meta.glob('/node_modules/flag-icons/flags/4x3/*.svg', {as: 'raw'})).map(([key, value]) => {
    const filename = key.split('/').pop()!.split('.').shift()
    return [filename, value]
  }),
)

// Lazily load the icon
const loadIcon = async () => {
  if (nameLowerCase.value) {
    icon.value = await icons?.[nameLowerCase.value]?.()
  }
}

loadIcon()
</script>
